.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f2f2f2;
  }
  
  .form-container {
    display: flex;
    flex-direction: column;
    padding: 30px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .form-container h1 {
    margin-bottom: 20px;
  }
  
  .form-container label {
    margin-top: 10px;
  }
  
  .form-container input {
    margin-top: 1px;
    padding: 1px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .form-container button {
    margin-top: 20px;
    padding: 8px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .form-container button:hover {
    background-color: #0056b3;
  }
  