.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 60%;
  margin: 0 auto;
}

.form-container {
  display: flex;
  flex-direction: column;
  padding: 30px;
  display: flex;
  flex-direction: column;
  width: 60%;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-container h1 {
  margin-bottom: 20px;
}

.form-container label {
  margin-top: 10px;
}

.form-container input {
  margin-top: 5px;
  flex-direction: column;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-container button {
  margin-top: 20px;
  padding: 8px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-container button:hover {
  background-color: #0056b3;
}

.form-container div {
  color: rgb(0, 0, 0);
  font-size: 12px;
}

.form-container label {
  margin-top: 10px;
}

.form-container input {
  margin-top: 5px;
}

.register-button {
  /* 添加按鈕的正常顏色樣式 */
  background-color: #2f53f3;
  /* 其他按鈕樣式 */
}

.register-button-disabled {
  /* 添加按鈕的灰色樣式 */
  background-color: #cccccc;
  /* 其他按鈕樣式 */
}

.highlighted-text {
  color: blue;
}

.content-row {
  display: flex;
  flex-direction: row;
}

.content-column {
  flex: 1;
}

.left-column {
  padding-right: 20px;
}

.right-column {
  padding-left: 20px;
}

