.chatGPT-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
  }
  
  form {
    display: flex;
    width: 100%;
  }
  
  input {
    flex-grow: 1;
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 4px 0 0 4px;
    text-align: center;
    outline: none;
  }
  
  button {
    padding: 8px 16px;
    border: none;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .response-container {
    width: 100%;
    margin-top: 20px;
    text-align: center;
  }
  
  .response-container p {
    background-color: #f8f9fa;
    border-radius: 4px;
    padding: 16px;
    word-wrap: break-word;
  }